import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FallingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import facebook from "../../../assets/images/Facebook.png";
import google from "../../../assets/images/Google.png";
import signInLogo from "../../../assets/svg/sign-in-logo.svg";
import axios from "../../../components/Hooks/axios";
import { addAccount } from "../../../redux/slices/accountSlice";
import { setAuth, setUserObject } from "../../../redux/slices/authSlice";
import LoadingBg from "../Loading Bg/LoadingBg";
import classes from "./SignIn.module.scss";

const SignIn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const forgetPasswordHandler = () => {
		navigate("/forget-password");
	};

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [passIcon, setPassIcon] = useState(false);

	const handelSignIn = () => {
		if (email === "" || password === "") return toast.warn("Please fill all the fields");
		setBtnDisabled(true);

		const data = JSON.stringify({
			email: email,
			password: password,
		});

		axios
			.post("/auth/login", data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				// if (rememberMe) {
				// 	// localStorage.setItem("auth_userId", data.data._id);
				// 	// localStorage.setItem("auth_token", data.token);

				// 	Cookies.set("E_current_userId", JSON.stringify(data.data._id), { expires: 50 });
				// 	Cookies.set("E_token", JSON.stringify(data.token), { expires: 50 });
				// 	Cookies.set("rememberMe", "rememberMe");
				// } else {
				// 	// sessionStorage.setItem("auth_userId", data.data._id);
				// 	// sessionStorage.setItem("auth_token", data.token);

				// 	Cookies.set("E_current_userId", JSON.stringify(data.data._id));
				// 	Cookies.set("E_token", JSON.stringify(data.token));
				// }
				dispatch(setAuth({ userId: data.data._id, token: data.token, rememberMe }));
				dispatch(setUserObject(data.data));
				axios.defaults.headers.Authorization = data.token;
				setBtnDisabled(false);
				dispatch(addAccount({ userId: data.data._id, user: data.data, token: data.token }));
				navigate("/Home", { replace: true });
			})
			.catch(({ response }) => {
				toast.error(response?.data?.message);
				toast.error(response?.data?.error);
				setBtnDisabled(false);
			});
	};

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			axios
				.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`)
				.then(({ data }) => {
					axios
						.post("/auth/googleRegister", data)
						.then(({ data }) => {
							if (data.status) {
								// Cookies.set("E_current_userId", JSON.stringify(data.data._id));
								// Cookies.set("E_token", JSON.stringify(data.token));
								dispatch(setAuth({ userId: data.data._id, token: data.token }));
								dispatch(setUserObject(data.data));
								axios.defaults.headers.Authorization = data.token;
								setBtnDisabled(false);
								navigate("/Home", { replace: true });
							}
						})
						.catch((e) => console.log(e));
				})
				.catch((e) => console.log(e));
		},
		onError: (tokenResponse) => console.log(tokenResponse),
	});

	const responseFacebook = (response) => {
		console.log(response);
	};

	return (
		<div className={classes["main-container"]}>
			<LoadingBg />
			<div className={classes["sub-container"]}>
				<div className={classes.header}>
					<div className={classes["text-container"]}>
						<p className={classes.heading}>Sign In</p>
						<p className={classes.text}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est tortor gravida nibh id at.
						</p>
					</div>
					<div className={classes.image}>
						<img src={signInLogo} alt="" />
					</div>
				</div>

				<div className={classes.form}>
					<div className={classes["input-container"]}>
						<div className={classes.input}>
							<label htmlFor="email" className={classes.label}>
								E-mail or Phone no
							</label>
							<input
								type="email"
								className={classes["input-box"]}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>

						<div className={classes.input}>
							<label htmlFor="password" className={classes.label}>
								Password
							</label>

							<div className={classes.inputPass}>
								<input
									type={passIcon ? "text" : "password"}
									// className={classes["input-box"]}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>

								<span onClick={() => setPassIcon(!passIcon)}>
									{passIcon ? <FaEye size={"2.5rem"} /> : <FaEyeSlash size={"2.5rem"} />}
								</span>
							</div>

							<Link to="/signup" className={classes.ArrowBtn}>
								{">"}
							</Link>
						</div>

						<div className={classes["forget-remember"]}>
							<section className={classes.remember}>
								<input
									type="radio"
									className={classes["radio-button"]}
									checked={rememberMe}
									onChange={(e) => setRememberMe(e.target.checked)}
									id="Remember_me"
								/>
								<label htmlFor="Remember_me" className={classes["remember-me"]}>
									Remember me
								</label>
							</section>
							<p className={classes.link} onClick={forgetPasswordHandler}>
								Forget Password
							</p>
						</div>
					</div>

					<div className={classes.actions}>
						<button type="submit" className={classes.btn} onClick={handelSignIn} disabled={btnDisabled}>
							{btnDisabled ? (
								<FallingLines color="#fff" width="60" visible={true} ariaLabel="falling-lines-loading" />
							) : (
								"Sign In"
							)}
						</button>
					</div>

					<div className={classes.design}>
						<div className={classes.line}></div>
						<p className={classes.continue}>Continue With</p>
						<div className={classes.line}></div>
					</div>

					<div className={classes.footer}>
						<button onClick={() => login()}>
							<img src={google} alt="" />
						</button>

						<FacebookLogin
							appId="1676206786190045"
							autoLoad={false}
							fields="name,email,picture"
							callback={responseFacebook}
							render={(renderProps) => (
								<button onClick={renderProps.onClick}>
									<img src={facebook} alt="" />
								</button>
							)}
						/>

						{/* <button>
							<img src={facebook} alt="" />
						</button> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
