import classes from "./LoadingBg.module.css";
import bigLogo from "../../../assets/svg/New Logo  - White.svg";
import logo from "../../../assets/svg/Component 2.svg";
import { useState, useEffect } from "react";

const LoadingBg = (props) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 427);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 427);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

    return isMobile ? (
		<div className={`${classes["landing-bg"]} ${props.className}`}>
			<div className={classes["logo-container"]}>
				<img src={logo} alt="" draggable="false" className={classes["small-logo"]} />
			</div>
		</div>
	) : (
		<div className={`${classes["landing-bg"]} ${props.className}`}>
			<div className={classes["logo-container"]}>
				<img src={bigLogo} alt="" draggable="false" className={classes["big-logo"]} />
				<img src={logo} alt="" draggable="false" className={classes["small-logo"]} />
			</div>
		</div>
	);
};

export default LoadingBg;
