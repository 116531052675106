import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../../components/Hooks/axios";
import { setUserObject } from "../../../redux/slices/authSlice";
import { setTempEmail } from "../../../redux/slices/tempSlice";
import styles from "./MobileChangePassword.module.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ok from "../../../assets/images/ok.png";

const ChangePassword = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { tempEmail } = useSelector((state) => state.temp);

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [btnDisable, setBtnDisable] = useState(false);
	const [passIcon1, setPassIcon1] = useState(false);
	const [passIcon2, setPassIcon2] = useState(false);


	useEffect(() => {
		if (tempEmail === "") navigate(-1, { replace: true });
	}, []);

	const passwordValidate = () => {
		const number = /\d/;
		const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
		const uppercaseRegex = /[A-Z]/;

		if (password.length < 8) {
			toast.error("Password length must be 8 or more!!");
			return true;
		}

		if (!number.test(password)) {
			toast.error("Please add a number!!");
			return true;
		}

		if (!specialCharacterRegex.test(password)) {
			toast.error("Please add a special character!!");
			return true;
		}

		if (!uppercaseRegex.test(password)) {
			toast.error("Please add a uppercase latter!!");
			return true;
		}
		return false;
	};

	const handelChangePassword = () => {
		if (password === "" || confirmPassword === "") return toast.warn("Please fill all the fields !");
		if (passwordValidate()) return;
		if (password !== confirmPassword) return toast.error("Password and confirm password does not match !");
		setBtnDisable(true);

		var raw = JSON.stringify({
			email: tempEmail,
			password: password,
		});

		axios
			.post("/auth/changepassword", raw, {
				headers: {
					"Content-Type": "application/json",
					Authorization: location.state.token,
				},
			})
			.then(({ data }) => {
				toast.success(data.message);
				setBtnDisable(false);
				if (location.state?.pageLocation === "/Privacy") {
					axios
						.get(`/auth/get-user-details/${location.state?.userId}`)
						.then(({ data }) => {
							dispatch(setUserObject(data));
							navigate(location.state?.pageLocation, { replace: true });
							dispatch(setTempEmail(""));
						})
						.catch((e) => console.log(e));
				} else {
					navigate("/signin", { replace: true });
					dispatch(setTempEmail(""));
				}
			})
			.catch(({ response: { data } }) => {
				setBtnDisable(false);
				toast.error(data.error);
			});
	};

	if (tempEmail)
		return (
			<div className={styles.ChangePassword}>
				<div className={styles.container}>
					<div className={styles.header}>
						<div className={styles.textContainer}>
							<p className={styles.heading}>Change Password</p>
							<p className={styles.text}>Please change your password and protect your account</p>
						</div>
					</div>

					<div className={styles.passwordWrapper}>
						<div className={styles.newPassword}>
							<div className={styles.lableContainer}>
								<label className={styles.label}>New Password</label>
							</div>
							<div className={styles.input}>
								<div className={styles.inputPass}>
									<input
										type={passIcon1 ? "text" : "password"}
										className={styles.inputBox}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<span onClick={() => setPassIcon1(!passIcon1)}>
										{passIcon1 ? <FaEye size={"2.5rem"} /> : <FaEyeSlash size={"2.5rem"} />}
									</span>
								</div>
							</div>
							<div className={styles.strengthBar}>
								{/[A-Z]/.test(password) ? <span className={`${styles.bar} ${styles.bar1}`}></span> : null}
								{/[a-z]/.test(password) ? <span className={`${styles.bar} ${styles.bar2}`}></span> : null}
								{/[0-9]/.test(password) ? <span className={`${styles.bar} ${styles.bar3}`}></span> : null}
								{/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password) ? (
									<span className={`${styles.bar} ${styles.bar4}`}></span>
								) : null}
								{password.length >= 8 ? <span className={`${styles.bar} ${styles.bar5}`}></span> : null}
							</div>
						</div>

						<ul>
							<li>
								{/[A-Z]/.test(password) ? <img src={ok} alt="" /> : null}
								Password must have one uppercase letter
							</li>
							<li>
								{/[a-z]/.test(password) ? <img src={ok} alt="" /> : null}
								Password must have one lowercase letter
							</li>
							<li>
								{/[0-9]/.test(password) ? <img src={ok} alt="" /> : null}
								Password must contain at least one number
							</li>
							<li>
								{/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password) ? <img src={ok} alt="" /> : null}
								Password must contain one special character “@$”{" "}
							</li>
							<li>
								{password.length >= 8 ? <img src={ok} alt="" /> : null}
								Password must be 8 Digits long
							</li>
						</ul>

						<div className={`${styles.newPassword} ${styles.confirmPassword}`}>
							<div className={styles.lableContainer}>
								<label className={styles.label}>Confirm Password</label>
							</div>
							<div className={styles.input}>
								<div className={styles.inputPass}>
									<input
										type={passIcon2 ? "text" : "password"}
										className={styles.inputBox}
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
									/>
									<span onClick={() => setPassIcon2(!passIcon2)}>
										{passIcon2 ? <FaEye size={"2.5rem"} /> : <FaEyeSlash size={"2.5rem"} />}
									</span>
								</div>
							</div>
							{confirmPassword.length > 0 ? (
								password === confirmPassword ? (
									<ul>
										<li>
											<img src={ok} alt="" />
											Password is matched with above password
										</li>
									</ul>
								) : (
									<ul>
										<li>Password does not match with above password</li>
									</ul>
								)
							) : (
								<div></div>
							)}
						</div>
					</div>

					<div className={styles.submitButtonContainer}>
						<button
							className={`${styles.signInButton} ${styles.activeButton} ${styles.submitButton}`}
							onClick={handelChangePassword}
							disabled={btnDisable}>
							Change Password
						</button>
					</div>
				</div>
			</div>
		);
};

export default ChangePassword;
