import React, { useState } from "react";
import { FaBackspace, FaInfoCircle, FaStar } from "react-icons/fa";
import styles from "./InfoButton.module.scss";

const InfoButton = () => {
	const [showTooltip, setShowTooltip] = useState(true);

	const [rating, setRating] = useState(3);

	const handleButtonClick = () => {
		setShowTooltip(!showTooltip);
	};
	return (
		<div className={styles.InfoButtonContainer}>
			<button className={styles.InfoButton} onClick={handleButtonClick}>
				<FaInfoCircle color="#46F2DB" />
			</button>

			<div className={`${styles.Tooltip} ${showTooltip ? styles.Active : ""}`}>
				<div className={styles.Cross} onClick={() => setShowTooltip(false)}>
					<FaBackspace size={"2.3rem"} />
				</div>
				<p>
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odio id tenetur quis, quia unde incidunt voluptate temporibus
					magni dicta sint corrupti nostrum consequuntur! Hic voluptas assumenda, modi neque voluptatum sunt totam alias debitis
					magni quo ullam minus veniam, dolorum labore pariatur aliquam, vero molestiae? Id deserunt nisi mollitia non facere nam
				
				</p>
				<a href="https://google.com" target="_blank">
					Some link of google drive
				</a>

				{/* <div className={styles.StarComponent}>
					{[...Array(5)].map((_, i) => (
						<FaStar
							key={i}
							className={styles.Star}
							size={30}
							onClick={() => setRating(i + 1)}
							color={rating < i + 1 ? "#fff" : "#00a498"}
						/>
					))}
				</div> */}
			</div>
		</div>
	);
};

export default InfoButton;
