import React from "react";
import styles from "./MobileSignIn.module.scss";
import LoadingBg from "../Loading Bg/LoadingBg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import facebook from "../../../assets/images/Facebook.png";
import google from "../../../assets/images/google1.png";
import { useGoogleLogin } from "@react-oauth/google";
import { addAccount } from "../../../redux/slices/accountSlice";
import axios from "../../../components/Hooks/axios";
import { setAuth, setUserObject } from "../../../redux/slices/authSlice";

const MobileSignIn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [passIcon1, setPassIcon1] = useState(false);

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			axios
				.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`)
				.then(({ data }) => {
					axios
						.post("/auth/googleRegister", data)
						.then(({ data }) => {
							if (data.status) {
								// Cookies.set("E_current_userId", JSON.stringify(data.data._id));
								// Cookies.set("E_token", JSON.stringify(data.token));
								dispatch(setAuth({ userId: data.data._id, token: data.token }));
								dispatch(setUserObject(data.data));
								axios.defaults.headers.Authorization = data.token;
								setBtnDisabled(false);
								navigate("/Home", { replace: true });
							}
						})
						.catch((e) => console.log(e));
				})
				.catch((e) => console.log(e));
		},
		onError: (tokenResponse) => console.log(tokenResponse),
	});

	const responseFacebook = (response) => {
		console.log(response);
	};

	const handelSignIn = () => {
		if (email === "" || password === "") return toast.warn("Please fill all the fields");
		setBtnDisabled(true);

		const data = JSON.stringify({
			email: email,
			password: password,
		});

		axios
			.post("/auth/login", data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(({ data }) => {
				dispatch(setAuth({ userId: data.data._id, token: data.token, rememberMe }));
				dispatch(setUserObject(data.data));
				axios.defaults.headers.Authorization = data.token;
				setBtnDisabled(false);
				dispatch(addAccount({ userId: data.data._id, user: data.data, token: data.token }));
				navigate("/Home", { replace: true });
			})
			.catch(({ response }) => {
				toast.error(response?.data?.message);
				toast.error(response?.data?.error);
				setBtnDisabled(false);
			});
	};
    
    const forgetPasswordHandler = () => {
		navigate("/forget-password");
	};

	return (
		<div className={styles.mainContainer}>
			<LoadingBg />
			<div className={styles.form}>
				<div className={styles.buttons}>
					<button
						className={styles.signInButton}
						onClick={() => {
							navigate("/signup");
						}}>
						Sign Up
					</button>
					<button className={`${styles.signInButton} ${styles.activeButton}`}>Sign In</button>
				</div>

				<div className={styles.inputContainer}>
					<div className={styles.input}>
						<label className={styles.label}>E-mail or Phone no</label>
						<input
							type="email"
							className={styles.inputBox}
							value={email}
							placeholder="Your Email Id or Phone Number"
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>

					<div className={styles.passwordWrapper}>
						<div className={styles.input}>
							<label className={styles.label}>New password</label>

							<div className={styles.inputPass}>
								<input
									type={passIcon1 ? "text" : "password"}
									className={styles.inputBox}
									placeholder="Enter Your Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<span onClick={() => setPassIcon1(!passIcon1)}>
									{passIcon1 ? <FaEye size={"2.5rem"} /> : <FaEyeSlash size={"2.5rem"} />}
								</span>
							</div>
						</div>
					</div>

					<div className={styles.forgetRemember}>
						<section className={styles.remember}>
							<input
								type="checkbox"
								className={styles.radioButton}
								checked={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
								id="Remember_me"
							/>
							<label htmlFor="Remember_me" className={styles.rememberMe}>
								Remember me
							</label>
						</section>
						<p className={styles.link} onClick={forgetPasswordHandler}>
							Forget Password
						</p>
					</div>

					<div className={styles.submitButtonContainer}>
						<button
							className={`${styles.signInButton} ${styles.activeButton} ${styles.submitButton}`}
							onClick={handelSignIn}
							disabled={btnDisabled}>
							Sign In
						</button>
					</div>
				</div>

				<div className={styles.design}>
					<p className={styles.continue}>Continue With</p>
				</div>

				<div className={styles.footer}>
					<button onClick={() => login()}>
						<img src={google} alt="" className={styles.google} />
					</button>
					<button>
						<img src={facebook} alt="" className={styles.facebook} />
					</button>
				</div>
			</div>
		</div>
	);
};

export default MobileSignIn;
