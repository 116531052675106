import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ChangeLogo from "../../../assets/svg/Change password.svg";
import axios from "../../../components/Hooks/axios";
import LoadingBg from "../Loading Bg/LoadingBg";
import classes from "./Verification.module.css";

const Verification = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { tempEmail } = useSelector((state) => state.temp);

	const [otp, setOtp] = useState(new Array(4).fill(""));
	const [activeOtp, setActiveOtp] = useState(0);
	const [disableBtn, setDisableBtn] = useState(false);
	const [sendOTPBtn, setSendOTPBtn] = useState(true);
	const [countDown, setCountDown] = useState(30);
	const inputRef = useRef();

	useEffect(() => {
		if (tempEmail === "") navigate(-1, { replace: true });
	}, [tempEmail]);

	useEffect(() => {
		inputRef.current?.focus();
	}, [activeOtp]);

	useEffect(() => {
		setSendOTPBtn(true);
		const timer = setInterval(function () {
			if (countDown > 0) {
				setCountDown(countDown - 1);
			}
		}, 1000);
		if (countDown === 0) setSendOTPBtn(false);
		return () => clearInterval(timer);
	}, [sendOTPBtn, countDown]);

	const handelOnChange = (e, index) => {
		const value = e.target.value;
		const newOtp = [...otp];
		newOtp[index] = value.substring(value.length - 1);
		setActiveOtp(index + 1);
		setOtp(newOtp);
	};

	const handelSubmit = () => {
		if (otp[0] === "" || otp[1] === "" || otp[2] === "" || otp[3] === "") {
			inputRef.current?.focus();
			return toast.warn("Please fill !!");
		}
		setDisableBtn(true);
		axios
			.post("/auth/verify-otp", {
				email: tempEmail,
				otp: otp.join(""),
			})
			.then(({ data }) => {
				setDisableBtn(false);
				toast.success(data.message);
				if (data.success) {
					if (location.state?.signUp) {
						navigate("/interest", { replace: true });
						axios.defaults.headers.Authorization = data.token;
					} else {
						navigate("/change-password", {
							state: {
								token: data.token,
								pageLocation: location.state?.pageLocation,
								userId: location.state?.userId,
							},
						});
					}
				}
			})
			.catch(({ response }) => {
				setDisableBtn(false);
				console.log(response);
				toast.error(response.data.message);
			});
	};

	const handelSendOTP = () => {
		setSendOTPBtn(true);
		const id = toast.loading("Please wait...");
		axios
			.post("/auth/otp", {
				email: tempEmail,
			})
			.then(({ data }) => {
				setCountDown(5);
				toast.update(id, {
					render: data.message,
					type: "success",
					isLoading: false,
					autoClose: 3000,
				});
				toast.info(data.otp, {
					autoClose: 10000,
				});
				setOtp(["", "", "", ""]);
				setActiveOtp(0);
				inputRef.current?.focus();
			})
			.catch(({ response }) => {
				toast.error(response.data.error);
			});
	};

	return (
		<div className={classes["main-container"]}>
			<LoadingBg />
			<div className={classes["sub-container"]}>
				<div className={classes.header}>
					<div className={classes["text-container"]}>
						<p className={classes.heading}>Verification Code</p>
						<p className={classes.text}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est tortor gravida nibh id at.
						</p>
					</div>
					<div className={classes.image}>
						<img src={ChangeLogo} alt="" />
					</div>
				</div>
				<div className={classes.form}>
					<div className={classes.input}>
						{otp.map((_, index) => (
							<input
								ref={index === activeOtp ? inputRef : null}
								key={index}
								type="number"
								className={classes["input-box"]}
								value={otp[index]}
								onChange={(e) => handelOnChange(e, index)}
							/>
						))}
					</div>
					<div className={classes.actions}>
						<button type="submit" className={classes.btn} onClick={handelSubmit} disabled={disableBtn}>
							Verify
						</button>
					</div>
					<div className={classes.footer}>
						<p className={classes.link} onClick={() => navigate("/signin")}>
							Back to Sign In
						</p>
						<section className={classes.time}>
							<button className={classes.text2} onClick={handelSendOTP} disabled={sendOTPBtn}>
								Resend Code In:
							</button>
							<p className={classes.timer}>{countDown}s</p>
						</section>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Verification;
