import React from "react";
import styles from "./MobileForgetPassword.module.scss";
import LoadingBg from "../Loading Bg/LoadingBg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../components/Hooks/axios";
import { setTempEmail } from "../../../redux/slices/tempSlice";

const MobileForgetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [emailNumber, setEmailNumber] = useState("");
	const [btnDisable, setBtnDisable] = useState(false);

	const changePasswordHandler = () => {
		if (emailNumber === "") return toast.warn("Please enter Email or Number");
		setBtnDisable(true);
		axios
			.post("/auth/otp", {
				email: emailNumber,
			})
			.then(({ data }) => {
				toast.success(data.message);
				toast.info(data.otp, {
					autoClose: 10000,
				});
				dispatch(setTempEmail(emailNumber));
				navigate("/verification-code");
				setBtnDisable(false);
			})
			.catch(({ response }) => {
				toast.error(response.data.error);
				setBtnDisable(false);
			});
	};

	return (
		<div className={styles.mainContainer}>
			<LoadingBg />
			<div className={styles.form}>
				<div className={styles.header}>
					<div className={styles.textContainer}>
						<p className={styles.heading}>Forgot Password</p>
						<p className={styles.text}>
							Please enter your registered mobile number or email id to reset your password
						</p>
					</div>
				</div>

				<div className={styles.inputContainer}>
					<div className={styles.input}>
						<label className={styles.label}>E-mail or Phone no</label>
						<input
							type="email"
							className={styles.inputBox}
							value={emailNumber}
							placeholder="Your Email Id or Phone Number"
							onChange={(e) => setEmailNumber(e.target.value)}
						/>
					</div>

					<div className={styles.submitButtonContainer}>
						<button
							className={`${styles.signInButton} ${styles.activeButton} ${styles.submitButton}`}
							onClick={ changePasswordHandler }
							disabled={btnDisable}>
							Submit
						</button>
					</div>
				</div>

				<div className={styles.design}>
					<p className={styles.continue} onClick={() => navigate("/signin")}>
						Back To Sign in
					</p>
				</div>
			</div>
		</div>
	);
};

export default MobileForgetPassword;
